import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const config = {
  apiKey: 'AIzaSyCofzVX7MY_S7sctiPkFIeMNr6mGaffhaE',
  authDomain: 'quickapi-io.firebaseapp.com',
  databaseURL: 'https://quickapi-io.firebaseio.com',
}

if (typeof window !== 'undefined') {
  firebase.initializeApp(config)
}

export default firebase
